@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

$blue: #0e14aa;

* {
    margin: 0;
    font-family: "Poppins", "sans-serif";
    padding: 0;
}

.logo {
    width: 107px;
    height: 114px;
}

.contact-link {
    &:hover {
        .arrow-wrapper {
            right: -75px;
        }
    }

    .arrow-wrapper {
        width: 45px;
        height: 45px;
        right: -50px;
        top: 10px;
        transform: rotate(-45deg);
        transition: all 0.2s;
        position: absolute;
        pointer-events: none;
    }

    .arrow {
        display: inline-block;
        width: 45px;
        height: 45px;
        background-image: url("./assets/right_arrow.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        pointer-events: none;
        position: absolute;
    }
}

.partners-wrapper {
    li {
        a {
            transition: all 0.2s;
            position: relative;
            -webkit-text-stroke: 1px #0e14aa;

            &:hover {
                color: $blue;

                &:after {
                    right: -60px;
                    opacity: 1;
                }
            }

            &:after {
                content: "";
                width: 35px;
                height: 35px;
                position: absolute;
                opacity: 0;
                transition: all 0.2s;
                right: -50px;
                background-image: url("./assets/right_arrow.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                transform: rotate(-45deg);
                pointer-events: none;
            }
        }
    }
}

@media screen and (max-width: 640px) {
    .contact-link {
        .arrow-wrapper {
            right: -15px;
            top: 35px;
        }
        .arrow {
            width: 35px;
            height: 35px;
            right: -40px;
        }
    }
    .partners-wrapper {
        li {
            a {
                -webkit-text-stroke: 0.8px #0e14aa;

                &:after {
                    width: 25px;
                    height: 25px;
                    right: -30px;
                    top: 5px;
                    opacity: 1;
                }
            }
        }
    }
}
